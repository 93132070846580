var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"\n    relative\n    grid grid-cols-1\n    items-stretch\n    bg-hero-pattern bg-cover bg-left-top bg-no-repeat\n    lg:grid-cols-2 lg:bg-none\n  ",class:{ 'lg:bg-silver-grey': _vm.$route.name !== 'index' }},[_c('div',{staticClass:"\n      relative\n      flex flex-col\n      justify-between\n      order-2\n      p-4\n      w-full\n      lg:justify-self-end lg:order-1 lg:pr-12 lg:max-w-2xl\n    "},[_c('div',{staticClass:"flex items-center justify-between w-full lg:justify-start",class:{
        relative: _vm.isMenuOpen,
      }},[_c('nuxt-link',{staticClass:"w-3/5 sm:w-2/5 lg:w-3/5",class:_vm.logoClass,attrs:{"to":"/"}},[_c('AtomsLogo')],1),_vm._v(" "),_c('button',{class:{
          'text-white': !_vm.isMenuOpen,
          'text-charcoal-black absolute z-20 right-0': _vm.isMenuOpen,
        },on:{"click":_vm.toggleMenu}},[_c('AtomsIconMenu',{staticClass:"lg:hidden"})],1)],1),_vm._v(" "),(_vm.$route.name === 'index')?_c('div',{staticClass:"mb-12 mt-10 text-center lg:my-20 lg:my-28 lg:text-left"},[_c('span',{staticClass:"\n          block\n          text-white text-2xl\n          font-bold\n          lg:text-charcoal-black lg:text-4xl\n        "},[_vm._v("\n        We are here to help you\n      ")]),_vm._v(" "),_c('span',{staticClass:"\n          inline-block\n          mt-2\n          px-3\n          py-1\n          text-white text-4xl\n          font-bold\n          bg-dusty-pink\n          lg:block lg:m-0 lg:p-0 lg:text-dusty-pink lg:text-6xl lg:bg-white\n        "},[_vm._v("\n        conquer the world!\n      ")]),_vm._v(" "),_c('p',{staticClass:"\n          mt-12\n          mx-auto\n          text-white text-lg\n          leading-relaxed\n          sm:w-4/5\n          lg:w-full lg:text-charcoal-black\n        "},[_vm._v("\n        We specialize in designing, building, shipping and scaling beautiful,\n        usable products with blazing-fast efficiency\n      ")])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"\n      relative\n      block\n      order-1\n      w-full\n      bg-hero-pattern bg-cover bg-center\n      object-cover\n      transition-all\n      duration-300\n      ease-in-out\n      lg:order-2\n    "},[_c('transition',{attrs:{"enter-active-class":"transition ease-out duration-100","enter-class":"transform opacity-0 scale-95","enter-to-class":"transform opacity-100 scale-100","leave-active-class":"transition ease-in duration-75","leave-class":"transform opacity-100 scale-100","leave-to-class":"transform opacity-0 scale-95"}},[(_vm.isMenuOpen || !_vm.isSmallScreen)?_c('nav',{staticClass:"\n          absolute\n          z-10\n          left-0\n          top-0\n          w-full\n          list-none\n          lg:flex lg:mt-12 lg:pl-12 lg:max-w-2xl\n        ",class:{
          'justify-between hidden text-white pl-4': !_vm.isMenuOpen,
          'pb-20 flex flex-col bg-white w-full h-screen text-charcoal-black justify-evenly items-center text-xl':
            _vm.isMenuOpen,
        }},[_c('li',[_c('nuxt-link',{staticClass:"px-4 py-2 hover:border-white",attrs:{"to":"/","exact":""}},[_vm._v("\n            Home\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{staticClass:"px-4 py-2 hover:border-white",attrs:{"to":"/services"}},[_vm._v("\n            Services\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{staticClass:"px-4 py-2 hover:border-white",attrs:{"to":"/career"}},[_vm._v("\n            Career\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{staticClass:"px-4 py-2 hover:border-white",attrs:{"to":"/case-studies"}},[_vm._v("\n            Case studies\n          ")])],1),_vm._v(" "),(false)?_c('li',[_c('nuxt-link',{staticClass:"px-4 py-2 hover:border-white",attrs:{"to":"/blog"}},[_vm._v("\n            Blog\n          ")])],1):_vm._e(),_vm._v(" "),(false)?_c('li',[_c('nuxt-link',{staticClass:"px-4 py-2 hover:border-white",attrs:{"to":"/contact"}},[_vm._v("\n            Contact\n          ")])],1):_vm._e()]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }