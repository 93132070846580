//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Header',
  data() {
    return {
      windowWidth: 0,
      isMenuOpen: false,
      logoClass: 'text-charcoal-black',
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth
    document.documentElement.style.overflow = 'auto'

    if (this.isSmallScreen) {
      this.logoClass = 'text-white'
    }
  },
  computed: {
    isSmallScreen() {
      return this.windowWidth < 1024
    },
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    hideMenu() {
      this.isMenuOpen = false
    },
  },
  watch: {
    isMenuOpen(value) {
      document.documentElement.style.overflow = value ? 'hidden' : 'auto'
    },
    '$route.name'() {
      this.hideMenu()
    },
  },
}
