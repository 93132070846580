import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _828e4752 = () => interopDefault(import('../pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _5f665736 = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _288b6b82 = () => interopDefault(import('../pages/case-studies.vue' /* webpackChunkName: "pages/case-studies" */))
const _17a97a7b = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _9cd865e8 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _ab07aeda = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _58fc4b29 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _38e49b05 = () => interopDefault(import('../pages/career/junior-devops.vue' /* webpackChunkName: "pages/career/junior-devops" */))
const _b49ee812 = () => interopDefault(import('../pages/career/junior-fullstack-php-js-developer.vue' /* webpackChunkName: "pages/career/junior-fullstack-php-js-developer" */))
const _1d8d1a8c = () => interopDefault(import('../pages/career/regular-fullstack-php-js-developer.vue' /* webpackChunkName: "pages/career/regular-fullstack-php-js-developer" */))
const _12a37590 = () => interopDefault(import('../pages/career/senior-fullstack-php-js-developer.vue' /* webpackChunkName: "pages/career/senior-fullstack-php-js-developer" */))
const _9eb02a26 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _828e4752,
    name: "blog"
  }, {
    path: "/career",
    component: _5f665736,
    name: "career"
  }, {
    path: "/case-studies",
    component: _288b6b82,
    name: "case-studies"
  }, {
    path: "/contact",
    component: _17a97a7b,
    name: "contact"
  }, {
    path: "/privacy-policy",
    component: _9cd865e8,
    name: "privacy-policy"
  }, {
    path: "/services",
    component: _ab07aeda,
    name: "services"
  }, {
    path: "/terms-and-conditions",
    component: _58fc4b29,
    name: "terms-and-conditions"
  }, {
    path: "/career/junior-devops",
    component: _38e49b05,
    name: "career-junior-devops"
  }, {
    path: "/career/junior-fullstack-php-js-developer",
    component: _b49ee812,
    name: "career-junior-fullstack-php-js-developer"
  }, {
    path: "/career/regular-fullstack-php-js-developer",
    component: _1d8d1a8c,
    name: "career-regular-fullstack-php-js-developer"
  }, {
    path: "/career/senior-fullstack-php-js-developer",
    component: _12a37590,
    name: "career-senior-fullstack-php-js-developer"
  }, {
    path: "/",
    component: _9eb02a26,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
