import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=18688097&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsLogo: require('/home/studioars/applications/new-studioars.pl/components/atoms/Logo.vue').default,AtomsIconMenu: require('/home/studioars/applications/new-studioars.pl/components/atoms/IconMenu.vue').default})
