import { render, staticRenderFns } from "./default.vue?vue&type=template&id=ecfe89a0&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsHeader: require('/home/studioars/applications/new-studioars.pl/components/organisms/Header.vue').default,OrganismsFooter: require('/home/studioars/applications/new-studioars.pl/components/organisms/Footer.vue').default})
